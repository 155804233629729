const keywords = {
  n: 0,
  north: 0,
  nne: 22,
  ne: 45,
  ene: 67,
  e: 90,
  ese: 112,
  se: 135,
  sse: 157,
  s: 180,
  ssw: 202,
  sw: 225,
  wsw: 247,
  w: 270,
  wnw: 292,
  nw: 315,
  nnw: 337,
}

export function getAngleFromDirection(direction) {
  let numericDirection = 0

  if (!isNaN(direction)) {
    numericDirection = parseFloat(direction)
  } else if ((typeof direction) === 'string') {
    numericDirection = keywords[direction.toLowerCase()] ?? 0
  }

  return (numericDirection + 180) % 360
}

export function haversine(lat1, lon1, lat2, lon2) {
  const R = 6371e3; // metres
  const φ1 = lat1 * Math.PI/180; // φ, λ in radians
  const φ2 = lat2 * Math.PI/180;
  const Δφ = (lat2-lat1) * Math.PI/180;
  const Δλ = (lon2-lon1) * Math.PI/180;

  const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
        Math.cos(φ1) * Math.cos(φ2) *
        Math.sin(Δλ/2) * Math.sin(Δλ/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

  const d = R * c; // in metres
  return d
}
