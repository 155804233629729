export const wayFilterCategories = {
  common: {
    label: 'Allgemein',
  },
  sidepath: {
    label: 'Straßenbegleitend',
  },
  law: {
    label: 'Rechtlich',
  },
  surface: {
    label: 'Oberfläche',
  },
}

export const signFilterCategories = {
  common: {
    label: 'Allgemein',
  },
}

const KNOWN_MAXSPEED_OPTIONS = [
  100,
  70,
  60,
  50,
  40,
  30,
  20,
]

const KNOWN_SURFACE_OPTIONS = [
  { value: 'asphalt', label: 'Asphalt' },
  { value: 'paving_stones', label: 'Pflastersteine' },
  { value: 'concrete', label: 'Beton' },
  // { value: 'concrete:plates', label: 'Betonplatten' },
  { value: 'sett', label: 'Behauenes Steinpflaster' },
  // { value: 'unhewn_cobblestone', label: 'Unbehauenes Kopfsteinpflaster' },
]

export const filters = {
  // Way filters
  hide_ways: {
    label: 'Wege ausblenden',
    category: 'common',
    data: 'ways',
    type: 'boolean',
  },
  type: {
    label: 'Art',
    category: 'common',
    data: 'ways',
    type: 'multi-select',
    options: [
      { value: 'track', label: 'Baulicher Radweg' },
      { value: 'lane', label: 'Streifen' },
      { value: 'bicycle_road', label: 'Fahrradstraße' },
    ],
  },
  is_sidepath: {
    label: 'Straßenbegleitend',
    category: 'sidepath',
    type: 'single-select',
    disabled: true,
    data: 'ways',
    options: [
      { value: 'yes', label: 'Ja' },
      { value: 'no', label: 'nein' },
    ],
  },
  'is_sidepath:of': {
    label: 'begleitet Straßentyp',
    category: 'sidepath',
    type: 'multi-select',
    data: 'ways',
    options: [
      { value: 'motorway', label: 'Autobahn' },
      { value: 'trunk', label: 'Autobahnähnlich' },
      { value: 'primary', label: 'National' },
      { value: 'secondary', label: 'Überregional' },
      { value: 'tertiary', label: 'Durchfahrtsstraße' },
      { value: 'unclassified', label: 'Nebenstraße' },
      { value: 'residential', label: 'Wohnstraße' },
      { value: 'living_street', label: 'Verkehrsberuhigter Bereich' },
    ],
  },
  'is_sidepath:of:ref': {
    label: 'begleitet Straßenkategorie',
    category: 'sidepath',
    type: 'multi-select',
    data: 'ways',
    options: [
      { value: 'B', label: 'Bundesfernstraße' },
      { value: 'L', label: 'Landesstraße' },
      { value: 'K', label: 'Kreisstraße' },
      { value: 'none', label: 'Gemeindestraße' },
    ],
  },
  maxspeed: {
    label: 'Zulässige Höchtsgeschwindigkeit',
    category: 'sidepath',
    type: 'multi-select',
    data: 'ways',
    options: [
      ...KNOWN_MAXSPEED_OPTIONS.map((maxspeed) => ({
        value: String(maxspeed),
        label: `${maxspeed} km/h`,
      })),
      { value: 'walking', label: 'Schrittgeschwindigkeit' },
      { value: 'unknown', label: 'Unbekannt' },
    ],
  },
  maxspeedSource: {
    label: 'Quelle der zulässigen Höchstgeschwindigkeit',
    category: 'sidepath',
    type: 'multi-select',
    data: 'ways',
    options: [
      { value: 'motorway', label: 'Autobahn' },
      { value: 'rural', label: 'Außerorts' },
      { value: 'urban', label: 'Innerorts' },
      { value: 'sign', label: 'Streckenverbot' },
      { value: 'zone30', label: 'Tempo-30-Zone' },
      { value: 'bicycle_road', label: 'Fahrradstraße' },
      { value: 'living_street', label: 'Verkehrsberuhigter Bereich' },
      { value: 'other', label: 'Andere' },
      { value: 'unknown', label: 'Unbekannt' },
    ],
  },
  mandatory: {
    label: 'Benutzungspflichtig',
    category: 'law',
    type: 'single-select',
    disabled: false,
    data: 'ways',
    options: [
      { value: '', label: 'Egal' },
      { value: 'yes', label: 'Ja' },
      { value: 'no', label: 'nein' },
    ],
  },

  directions: {
    label: 'Richtungen',
    category: 'law',
    type: 'single-select',
    disabled: false,
    data: 'ways',
    options: [
      { value: '', label: 'Egal' },
      { value: 'oneway', label: 'Nur in eine Richtung befahrbar' },
      { value: 'twoway', label: 'In beide Richtungen befahrbar' },
      { value: 'unknown', label: 'Unbekannt' },
    ],
  },

  trafficSignForward: {
    label: 'Verkehrszeichen in Fahrbahnrichtung',
    category: 'law',
    type: 'multi-select',
    disabled: false,
    data: 'ways',
    options: [
      { value: 'none', label: 'Keine' },
      { value: '237', label: '237' },
      { value: '240', label: '240' },
      { value: '241-30', label: '241-30' },
      { value: '241-31', label: '241-31' },
      { value: '340', label: '340 (Schutzstreifen)' },
      { value: '1022-10', label: '1022-10 ("Rad frei")' },
    ],
  },

  trafficSignBackward: {
    label: 'Verkehrszeichen in Gegenrichtung',
    category: 'law',
    type: 'multi-select',
    disabled: false,
    data: 'ways',
    options: [
      { value: 'none', label: 'Keine' },
      { value: '237', label: '237' },
      { value: '240', label: '240' },
      { value: '241-30', label: '241-30' },
      { value: '241-31', label: '241-31' },
      { value: '340', label: '340 (Schutzstreifen)' },
      { value: '1022-10', label: '1022-10 ("Rad frei")' },
    ],
  },

  surface: {
    label: 'Oberflächenart',
    category: 'surface',
    type: 'multi-select',
    data: 'ways',
    options: [
      ...KNOWN_SURFACE_OPTIONS,
      // { value: 'other', label: 'Andere' },
      { value: 'unknown', label: 'Unbekannt' },
    ],
  },

  smoothness: {
    label: 'Ebenheit',
    category: 'surface',
    type: 'multi-select',
    data: 'ways',
    options: [
      { value: 'excellent', label: 'Exzellent' },
      { value: 'good', label: 'Gut' },
      { value: 'intermediate', label: 'Mittel' },
      { value: 'bad', label: 'Schlecht' },
      { value: 'unknown', label: 'Unbekannt' },
    ],
  },

  // Sign filters
  show_signs: {
    label: 'Zeichen anzeigen',
    category: 'common',
    data: 'signs',
    type: 'single-select',
    options: [
      { value: '', label: 'Nur von gefilterten Wegen' },
      { value: 'all', label: 'Alle' },
      { value: 'none', label: 'Keine' },
    ],
  },

  sign_ids: {
    label: 'Sign ID',
    category: 'hidden',
    data: 'signs',
    type: 'multi-select',
  },
}


export function applySignFilter(filter, filteredWays, sign) {
  const {
    cycleway,
  } = sign

  if (filter.show_signs === 'none') {
    return false
  } else if (filter.show_signs !== 'all') {
    if (!filteredWays.some((way) => way.id === cycleway?.id)) {
      return false
    }
  }

  if (Array.isArray(filter.sign_ids)) {
    return filter.sign_ids.includes(String(sign.id))
  }

  return true
}

export function applyWayFilter(filter, way) {
  const {
    tags,
    maxspeed,
    maxspeedSource,
    oneway,
  } = way

  if (filter.hide_ways === 'true') {
    return false
  }

  const isTrack = ['path', 'cycleway'].includes(tags.highway)

  if (
    filter.type && !filter.type.some((key) => {
      if (key === 'track') {
        return isTrack && tags.bicycle_road !== 'yes'
      }

      if (key === 'lane') {
        return !isTrack && tags.bicycle_road !== 'yes'
      }

      if (key === 'bicycle_road') {
        return tags.bicycle_road === 'yes'
      }

      return false
    })
  ) {
    return false
  }

  if (
    filter['is_sidepath:of']
      && (
        (isTrack && !filter['is_sidepath:of'].includes(tags['is_sidepath:of']))
          || (!isTrack && !filter['is_sidepath:of'].includes(tags.highway))
      )
  ) {
    return false
  }

  if (
    filter['is_sidepath:of:ref']
      && !filter['is_sidepath:of:ref'].some((key) => {
        const wayRef = isTrack ? tags['is_sidepath:of:ref'] : tags.ref
        if (key === 'none') {
          return !wayRef
        }
        return wayRef?.startsWith(key)
      })
  ) {
    return false
  }

  if (filter.maxspeed
      && !filter.maxspeed.some((key) => {
        const isNumber = !Number.isNaN(parseInt(key, 10))

        if (isNumber) {
          return maxspeed === key
        }

        if (key === 'unknown') {
          return !maxspeed && tags.highway !== 'living_street' && tags['is_sidepath:of'] !== 'living_street'
        }

        if (key === 'walking') {
          return tags.highway === 'living_street' || tags['is_sidepath:of'] === 'living_street'
        }

        return false
      })
     ) {
    return false
  }

  if (filter.maxspeedSource
      && !filter.maxspeedSource.some((key) => {
        if (['motorway', 'rural', 'urban', 'bicycle_road'].includes(key)) {
          return maxspeedSource === `DE:${key}`
        }

        if (key === 'sign') {
          return maxspeedSource === 'sign'
        }

        if (key === 'zone30') {
          return ['DE:zone30', 'DE:zone:30'].includes(maxspeedSource)
        }

        if (key === 'living_street') {
          return tags.highway === 'living_street' || tags['is_sidepath:of'] === 'living_street'
        }

        if (key === 'unknown') {
          return !maxspeedSource && tags.highway !== 'living_street' && tags['is_sidepath:of'] !== 'living_street'
        }

        if (key === 'other') {
          return maxspeedSource && ![
            'DE:motorway',
            'DE:rural',
            'DE:urban',
            'DE:bicycle_road',
            'sign',
            'DE:zone30',
            'DE:zone:30',
          ].includes(maxspeedSource)
        }

        return false
      })
     ) {
    return false
  }

  if (
    filter.mandatory === 'yes'
      && !way.mandatory
      && !way.mandatoryForward
      && !way.mandatoryBackward
  ) {
    return false
  }

  if (
    filter.mandatory === 'no'
      && (
        way.mandatory
          || way.mandatoryForward
          || way.mandatoryBackward
      )
  ) {
    return false
  }

  if (filter.directions) {
      // if (filter.directions === 'unknown' && oneway) {
      //   return false
      // }

      if (filter.directions === 'twoway' && oneway && oneway !== 'no') {
        return false
      }

    if (filter.directions === 'oneway' && oneway !== 'yes' && oneway !== '-1') {
      return false
    }
  }

  if (
    filter.trafficSignForward
      && !filter.trafficSignForward.some((key) => {
        if (key === 'none') {
          return !way.trafficSignForward || way.trafficSignForward === 'none'
        }

        return new RegExp(key).test(way.trafficSignForward)
      })
  ) {
    return false
  }

  if (
    filter.trafficSignBackward
      && !filter.trafficSignBackward.some((key) => {
        if (key === 'none') {
          return !way.trafficSignBackward || way.trafficSignBackward === 'none'
        }

        return new RegExp(key).test(way.trafficSignBackward)
      })
  ) {
    return false
  }

  const surface = tags['cycleway:surface'] || tags.surface

  if (
    filter.surface && !filter.surface.some((key) => {
      if (key === 'unknown') {
        return !surface
      }

      if (key === 'other') {
        return surface && !KNOWN_SURFACE_OPTIONS.map(({ value }) => value).includes(surface)
      }

      return key === surface
    })
  ) {
    return false
  }

  const smoothness = tags['cycleway:smoothness'] || tags.smoothness

  if (
    filter.smoothness && !filter.smoothness.some((key) => {
      if (key === 'unknown') {
        return !smoothness
      }

      return key === smoothness
    })
  ) {
    return false
  }

  return true
}
