import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import MapPage from './pages/map'


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<MapPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
