import React, {
  useEffect,
  useRef,
  useState,
} from 'react'
import { createRoot } from 'react-dom/client'
import L from 'leaflet'
import '../../leaflet-arrowheads'
import {
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
  useMap,
  useMapEvents,
} from 'react-leaflet'
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png'
import icon from 'leaflet/dist/images/marker-icon.png'
import shadow from 'leaflet/dist/images/marker-shadow.png'
import 'leaflet-rotatedmarker'
import 'leaflet-polylineoffset'
import {
  useSearchParams,
} from 'react-router-dom'
import 'sidebar-v2/js/leaflet-sidebar'
import {
  MdClose,
  MdFilterAlt,
  MdInfo,
} from 'react-icons/md';
import { DateTime } from 'luxon'
import FilterBar, { searchParamsToFilter } from '../../components/filter-bar'
import cycleways from '../../data/cycleways.json'
import signs from '../../data/signs.json'
import meta from '../../data/meta.json'
import {
  applySignFilter,
  applyWayFilter,
} from '../../filters'
import {
  getAngleFromDirection,
  haversine,
} from '../../utils'

import 'leaflet/dist/leaflet.css'
import 'sidebar-v2/css/leaflet-sidebar.css'

// Fix an issue causing the map marker icon to not be shown when using
// webpack
// https://machtfit.atlassian.net/browse/MAC-2692
// https://github.com/Leaflet/Leaflet/issues/4968
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: iconRetina,
  iconUrl: icon,
  shadowUrl: shadow,
})

const {
  REACT_APP_DEFAULT_MAP_CENTER,
  REACT_APP_DEFAULT_ZOOM_LEVEL,
  REACT_APP_PROVIDED_BY_NAME,
  REACT_APP_PROVIDED_BY_URL,
  REACT_APP_PROVIDED_BY_EMAIL,
  REACT_APP_PROVIDED_BY_MASTODON,
  REACT_APP_PROVIDED_BY_TWITTER,
  REACT_APP_IMPRINT_URL,
} = process.env

console.log({
  REACT_APP_DEFAULT_MAP_CENTER,
  REACT_APP_DEFAULT_ZOOM_LEVEL,
})

const MAP_CENTER = JSON.parse(REACT_APP_DEFAULT_MAP_CENTER)
const DEFAULT_ZOOM_LEVEL = parseInt(REACT_APP_DEFAULT_ZOOM_LEVEL, 10)

const icons = {
  205: 'https://upload.wikimedia.org/wikipedia/commons/6/6f/Zeichen_205_-_Vorfahrt_gew%C3%A4hren%21_StVO_1970.svg',
  206: 'https://upload.wikimedia.org/wikipedia/commons/2/2f/Zeichen_206_-_Halt%21_Vorfahrt_gew%C3%A4hren%21_StVO_1970.svg',
  209: 'https://upload.wikimedia.org/wikipedia/commons/b/b9/Zeichen_209_-_Vorgeschriebene_Fahrtrichtung%2C_rechts%2C_StVO_2017.svg',
  '209-30': 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Zeichen_209-30_-_Vorgeschriebene_Fahrtrichtung%2C_Geradeaus%2C_StVO_2017.svg',
  237: 'https://upload.wikimedia.org/wikipedia/commons/9/91/Zeichen_237_-_Sonderweg_Radfahrer%2C_StVO_1992.svg',
  239: 'https://upload.wikimedia.org/wikipedia/commons/5/5a/Zeichen_239_-_Sonderweg_Fu%C3%9Fg%C3%A4nger%2C_StVO_1992.svg',
  240: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Zeichen_240_-_Gemeinsamer_Fu%C3%9F-_und_Radweg%2C_StVO_1992.svg',
  241: 'https://upload.wikimedia.org/wikipedia/commons/8/86/Zeichen_241-30_-_getrennter_Rad-_und_Fu%C3%9Fweg%2C_StVO_1992.svg',
  '241-30': 'https://upload.wikimedia.org/wikipedia/commons/8/86/Zeichen_241-30_-_getrennter_Rad-_und_Fu%C3%9Fweg%2C_StVO_1992.svg',
  '241-31': 'https://upload.wikimedia.org/wikipedia/commons/6/68/Zeichen_241-31_-_getrennter_Fu%C3%9F-_und_Radweg%2C_StVO_1992.svg',
  '244.1': 'https://upload.wikimedia.org/wikipedia/commons/3/3c/Zeichen_244.1_-_Beginn_einer_Fahrradstra%C3%9Fe%2C_StVO_2013.svg',
  '244.2': 'https://upload.wikimedia.org/wikipedia/commons/f/f1/Zeichen_244.2_-_Ende_einer_Fahrradstra%C3%9Fe%2C_StVO_2013.svg',
  245: 'https://upload.wikimedia.org/wikipedia/commons/0/05/Zeichen_245_-_Bussonderfahrstreifen%2C_StVO_2013.svg',
  250: 'https://upload.wikimedia.org/wikipedia/commons/4/46/Zeichen_250_-_Verbot_f%C3%BCr_Fahrzeuge_aller_Art%2C_StVO_1970.svg',
  254: 'https://upload.wikimedia.org/wikipedia/commons/b/b0/Zeichen_254_-_Verbot_f%C3%BCr_Radfahrer%2C_StVO_1992.svg',
  259: 'https://upload.wikimedia.org/wikipedia/commons/f/f6/Zeichen_259_-_Verbot_f%C3%BCr_Fu%C3%9Fg%C3%A4nger%2C_StVO_1992.svg',
  260: 'https://upload.wikimedia.org/wikipedia/commons/b/bf/Zeichen_260_-_Verbot_f%C3%BCr_Kraftr%C3%A4der_und_Mofas_und_sonstige_mehrspurige_Kraftfahrzeuge%2C_StVO_1992.svg',
  267: 'https://upload.wikimedia.org/wikipedia/commons/7/77/Zeichen_267_-_Verbot_der_Einfahrt%2C_StVO_1970.svg',
  '274.1': 'https://upload.wikimedia.org/wikipedia/commons/e/eb/Zeichen_274.1_-_Beginn_einer_Tempo_30-Zone%2C_StVO_2013.svg',
  '274.2': 'https://upload.wikimedia.org/wikipedia/commons/1/14/Zeichen_274.2_-_Ende_einer_Tempo_30-Zone_%28einseitig%29%2C_StVO_2013.svg',
  '274-10': 'https://upload.wikimedia.org/wikipedia/commons/0/02/Zeichen_274-10_-_Zul%C3%A4ssige_H%C3%B6chstgeschwindigkeit%2C_StVO_2017.svg',
  '274-30': 'https://upload.wikimedia.org/wikipedia/commons/f/f4/Zeichen_274-30_-_Zul%C3%A4ssige_H%C3%B6chstgeschwindigkeit%2C_StVO_2017.svg',
  '277.1': 'https://upload.wikimedia.org/wikipedia/commons/b/ba/Zeichen_277.1_-_Verbot_des_%C3%9Cberholens_von_einspurigen_Fahrzeugen_f%C3%BCr_mehrspurige_Kraftfahrzeuge_und_Kraftr%C3%A4dern_mit_Beiwagen%3B_StVO_2020.svg',
  283: 'https://upload.wikimedia.org/wikipedia/commons/1/1b/Zeichen_283_-_Absolutes_Haltverbot%2C_StVO_2017.svg',
  '286-10': 'https://upload.wikimedia.org/wikipedia/commons/a/a0/Zeichen_286-10_-_Eingeschr%C3%A4nktes_Halteverbot_%28Anfang%29%2C_Rechtsaufstellung%2C_StVO_1992.svg',
  '325.1': 'https://upload.wikimedia.org/wikipedia/commons/e/e2/Zeichen_325.1_-_Beginn_eines_verkehrsberuhigten_Bereichs%2C_StVO_2009.svg',
  '357-50': 'https://upload.wikimedia.org/wikipedia/commons/2/2d/Zeichen_357-50_-_Durchl%C3%A4ssige_Sackgasse_f%C3%BCr_Fu%C3%9Fg%C3%A4nger_und_Radverkehr%2C_StVO_2009.svg',
  '1000-31': 'https://upload.wikimedia.org/wikipedia/commons/5/5e/Zusatzzeichen_1000-31_-_beide_Richtungen%2C_zwei_gegengerichtete_senkrechte_Pfeile%2C_StVO_1992.svg',
  '1000-33': 'https://upload.wikimedia.org/wikipedia/commons/0/06/Zusatzzeichen_1000-33_-_Radverkehr_im_Gegenverkehr%2C_StVO_1997.svg',
  '1001-30': 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f8/Zusatzzeichen_1001-30_-_auf_..._m%2C_StVO_1992.svg/1920px-Zusatzzeichen_1001-30_-_auf_..._m%2C_StVO_1992.svg.png',
  '1004-30': 'https://upload.wikimedia.org/wikipedia/commons/b/bb/Zusatzzeichen_1004-30_-_nach_100_m%2C_StVO_1992.svg',
  '1012-31': 'https://upload.wikimedia.org/wikipedia/commons/2/25/Zusatzzeichen_1012-31_-_Ende_%28600x330%29%2C_StVO_1992.svg',
  '1012-32': 'https://upload.wikimedia.org/wikipedia/commons/1/18/Zusatzzeichen_1012-32_-_Radfahrer_absteigen_%28420x231%29%2C_StVO_1992.svg',
  '1020-12': 'https://upload.wikimedia.org/wikipedia/commons/8/83/Zusatzzeichen_1020-12_-_Radfahrer_und_Anlieger_frei_%28450x600%29%2C_StVO_1992.svg',
  '1020-30': 'https://upload.wikimedia.org/wikipedia/commons/c/c1/Zusatzzeichen_1020-30_-_Anlieger_frei_%28600x330%29%2C_StVO_1992.svg',
  '1022-10': 'https://upload.wikimedia.org/wikipedia/commons/0/04/Zusatzzeichen_1022-10_-_Radfahrer_frei%2C_StVO_1992.svg',
  '1022-11': 'https://upload.wikimedia.org/wikipedia/commons/5/55/Zusatzzeichen_1022-11_-_Mofas_frei_%28600x450%29%2C_StVO_1992.svg',
  '1022-12': 'https://upload.wikimedia.org/wikipedia/commons/e/ec/Zusatzzeichen_1022-12_-_Kraftr%C3%A4der_auch_mit_Beiwagen%2C_Kleinkraftr%C3%A4der_und_Mofas_frei_%28600x450%29%2C_StVO_1992.svg',
  '1024-10': 'https://upload.wikimedia.org/wikipedia/commons/a/ae/Zusatzzeichen_1024-10_-_Personenkraftwagen_frei%2C_StVO_1992.svg',
  '1026-30': 'https://upload.wikimedia.org/wikipedia/commons/4/43/Zusatzzeichen_1026-30_-_Taxi_frei%2C_StVO_1992.svg',
  '1026-32': 'https://upload.wikimedia.org/wikipedia/commons/1/14/Zusatzzeichen_1026-32_-_Linienverkehr_frei_%28450x600%29%2C_StVO_1992.svg',
  '1026-36': 'https://upload.wikimedia.org/wikipedia/commons/4/41/Zusatzzeichen_1026-36_-_Landwirtschaftlicher_Verkehr_frei_%28450x600%29%2C_StVO_1992.svg',
}

function zoomToSignSizePixels(zoom) {
  if (zoom <= 14) {
    return 16
  }
  return 32
}

function styleObjectToString(styleObject) {
  let styleString = ''

  Object.keys(styleObject).forEach((attr) => {
    styleString += `${attr}: ${styleObject[attr]}; `
  })

  return styleString
}

function addDirectionArrows(polyline, zoom, oneway) {
  if (zoom < 16) {
    polyline.deleteArrowheads()
    return
  }

  let yawn
  let twoway = false

  if (oneway === 'yes') {
    yawn = 90
  } else if (oneway === '-1') {
    yawn = 180 + 90
  } else {
    yawn = 90
    twoway = true
  }

  polyline.arrowheads({
    size: '3m',
    frequency: '25m',
    yawn,
    twoway,
  })
}

function trafficSignToSigns(trafficSign, id) {
  if (!trafficSign || trafficSign === 'none' || trafficSign === 'DE:340') {
    return []
  }

  return (
    trafficSign
      .substring(3)
      .split(';')
      .map((group) => group.split(','))
      .flat()
      .map((sign) => {
        const signCode = sign.replace(/\[\d+\]/g, '')

        const baseSignCode = sign.match(/^\d+(-\d+)?/)?.[0]
        const iconUrl = icons[signCode] || icons[baseSignCode]

        if (!iconUrl) {
          console.log(`Unknown sign ${signCode} on element ${id}`)
        }
        return {
          signCode,
          iconUrl,
        }
      })
  )
}

function Post({ id, trafficSign, position, angle, zoom, tags }) {
  if (!trafficSign || trafficSign === 'none') {
    return null
  }

  const {
    note,
  } = tags

  const size = zoomToSignSizePixels(zoom)

  const postSigns = trafficSignToSigns(trafficSign, id)

  const signImgs = (
    postSigns
      .filter((sign) => sign.iconUrl)
      .map(({ iconUrl }) => {
        const style = styleObjectToString({
          width: `${size}px`,
          height: 'auto',
          position: 'relative',
          left: `+${size * 0.6}px`,
        })

        return `<img style="${style}" src="${iconUrl}" />`
      })
  )

  const icon = L.divIcon({
    className: 'sign-post-icon',
    html: signImgs.join(''),
    iconSize: [size, size],
  })

  return (
    <Marker
      position={position}
      icon={icon}
      rotationAngle={angle}
      rotationOrigin="50% 50%"
    >
      <Popup>
        {
          postSigns
            .filter((sign) => sign.iconUrl)
            .map(({ signCode, iconUrl }) => (
              <div key={signCode} style={{ textAlign: 'center' }}>
                <img
                  src={iconUrl}
                  alt={signCode}
                  style={{
                    width: '100%',
                    height: 'auto',
                    maxWidth: '130px',
                  }}
                />
                <div>
                  { signCode }
                </div>
              </div>
            ))
        }
        <p>
          { note }
        </p>
        <dl style={{ marginTop: '1em' }}>
          <dt>OpenStreetMap ID</dt>
          <dd><a href={`https://www.openstreetmap.org/node/${id}`}>{ id }</a></dd>
        </dl>
      </Popup>
    </Marker>
  )
}

function Sign({ sign, cycleway, zoom }) {
  const {
    tags,
    tags: {
      traffic_sign: ts,
      'traffic_sign:forward': tsF,
      'traffic_sign:backward': tsB,
      'cycleway:left:traffic_sign': leftTs,
      'cycleway:right:traffic_sign': rightTs,
      'cycleway:both:traffic_sign': bothTs,
      direction,
    },
    lon,
    lat,
    id,
    angle,
  } = sign

  const position = [lat, lon]

  if (ts) {
    return (
      <Post key={`post-${id}`} id={id} trafficSign={ts} position={position} angle={getAngleFromDirection(direction)} tags={tags} zoom={zoom} />
    )
  }

  if (!cycleway) {
    return null
  }

  return (
    <>
      <Post key={`post-forward-${id}`} id={id} trafficSign={tsF} position={position} angle={angle} tags={tags} zoom={zoom} />
      <Post key={`post-backward-${id}`} id={id} trafficSign={tsB} position={position} angle={angle + 180} tags={tags} zoom={zoom} />
      <Post key={`post-right-${id}`} id={id} trafficSign={rightTs ?? bothTs} position={position} angle={angle} tags={tags} zoom={zoom} />
      <Post key={`post-left-${id}`} id={id} trafficSign={leftTs ?? bothTs} position={position} angle={angle + 180} tags={tags} zoom={zoom} />
    </>
  )
}

function Track({ cycleway, zoom }) {
  const {
    id,
    tags: {
      'traffic_sign:forward': trafficSignForward,
      'traffic_sign:backward': trafficSignBackward,
      bicycle_road: bicycleRoad,
    },
    positions,
    name,
    ref,
    maxspeedHuman,
    maxspeedSourceHuman,
    legalTypeForward,
    legalTypeBackward,
    oneway,
  } = cycleway

  const mandatoryForward = Boolean(trafficSignForward?.match(/237|240|241/))
  const mandatoryBackward = Boolean(trafficSignBackward?.match(/237|240|241/))

  const isBicycleRoad = bicycleRoad === 'yes'

  let color

  if (isBicycleRoad) {
    color = 'purple'
  } else if (mandatoryForward && mandatoryBackward) {
    color = 'red'
  } else if (mandatoryForward) {
    color = 'blue'
  } else if (mandatoryBackward) {
    color = 'black'
  } else if (trafficSignForward && trafficSignBackward) {
    color = 'green'
  } else {
    color = 'yellow'
  }

  const signsForward = trafficSignToSigns(trafficSignForward, id)
  const signsBackward = trafficSignToSigns(trafficSignBackward, id)

  const polyRef = useRef()

  useEffect(() => {
    const polyline = polyRef.current

    if (!polyline) {
      return
    }
    addDirectionArrows(polyline, zoom, oneway)

  }, [polyRef, zoom, oneway])

  return (
    <Polyline
      ref={polyRef}
      positions={positions}
      pathOptions={{
        fillColor: color,
        color,
      }}
    >
      <Popup>
        <dl>
          <dt>OpenStreetMap ID</dt>
          <dd><a href={`https://www.openstreetmap.org/way/${id}`}>{ id }</a></dd>

          <dt>Art</dt>
          <dd>
            {
              isBicycleRoad
                ? 'Fahrradstraße'
                : 'Baulicher Radweg'
            }
          </dd>

          <dt>Beide Richtungen</dt>
          <dd>{ oneway === 'yes' ? 'Nein' : 'Ja' }</dd>

          <dt>
            {
              isBicycleRoad
                ? 'Name'
                : 'Begleitet Straße'
            }
          </dt>
          <dd>
            { [name, ref].filter(Boolean).join(' / ') || 'unbekannt '}
          </dd>

          <dt>Zulässige Höchstgeschwindigkeit der Straße</dt>
          <dd>
            { maxspeedHuman }
          </dd>

          <dt>Quelle für zulässige Höchstgeschwindigkeit</dt>
          <dd>
            { maxspeedSourceHuman }
          </dd>
        </dl>

        <h5>Vorwärtsrichtung</h5>
        <dl>
          <dt>Rechtlicher Typ</dt>
          <dd>{ legalTypeForward }</dd>

          {
            !isBicycleRoad && (
              <>
                <dt>Benutzungspflichtig?</dt>
                <dd>{ mandatoryForward ? 'ja' : 'nein' }</dd>
              </>
            )
          }

          <dt>Beschilderung</dt>
          <dd>
            {
              trafficSignForward === 'none'
                ? 'Keine'
                : (
                  signsForward
                    .filter((sign) => sign.iconUrl)
                    .map(({
                      signCode,
                      iconUrl,
                    }) => (
                      <div key={signCode}>
                        <img
                          src={iconUrl}
                          alt={signCode}
                          style={{
                            width: '40px',
                            height: 'auto',
                          }}
                          title={signCode}
                        />
                      </div>
                    ))
                )
            }
          </dd>
        </dl>
        {
          oneway !== 'yes' && (
            <>
              <h5>Gegenrichtung</h5>
              <dl>
                <dt>Rechtlicher Typ</dt>
                <dd>{ legalTypeBackward }</dd>

                {
                  !isBicycleRoad && (
                    <>

                      <dt>Benutzungspflichtig?</dt>
                      <dd>{ mandatoryBackward ? 'ja' : 'nein' }</dd>
                    </>
                  )
                }

                <dt>Beschilderung</dt>
                <dd>
                  {
                    trafficSignBackward === 'none'
                      ? 'Keine'
                      : (
                        signsBackward
                          .filter((sign) => sign.iconUrl)
                          .map(({ signCode, iconUrl }) => (
                            <div key={signCode}>
                              <img
                                src={iconUrl}
                                alt={signCode}
                                style={{
                                  width: '40px',
                                  height: 'auto',
                                }}
                                title={signCode}
                              />
                            </div>
                          ))
                      )
                  }
                </dd>
              </dl>
            </>
          )
        }
      </Popup>
    </Polyline>
  )
}

function Lane({ cycleway, zoom }) {
  let color

  const {
    id,
    tags: {
      name,
      ref,
    },
    positions,
    maxspeedHuman,
    maxspeedSourceHuman,
    side,
    mandatory,
    trafficSign,
    legalType,
    oneway,
  } = cycleway

  if (mandatory) {
    color = 'blue'
  } else if (trafficSign) {
    color = 'green'
  } else {
    color = 'yellow'
  }

  const laneSigns = trafficSignToSigns(trafficSign, id)

  const polyRef = useRef()

  useEffect(() => {
    const polyline = polyRef.current

    if (!polyline) {
      return
    }

    addDirectionArrows(polyline, zoom, oneway)
  }, [polyRef, zoom, oneway])

  return (
    <Polyline
      ref={polyRef}
      positions={positions}
      pathOptions={{ fillColor: color, color }}
      offset={side === 'right' ? 5 : -5}
    >
      <Popup>
        <dl>
          <dt>OpenStreetMap ID</dt>
          <dd><a href={`https://www.openstreetmap.org/way/${id}`}>{ id }</a></dd>

          <dt>Art</dt>
          <dd>Streifen</dd>

          <dt>Rechtlicher Typ</dt>
          <dd>{ legalType }</dd>

          <dt>Benutzungspflichtig?</dt>
          <dd>{ mandatory ? 'ja' : 'nein' }</dd>

          <dt>Beschilderung</dt>
          <dd>
            {
              trafficSign === 'none' || trafficSign === 'DE:340'
                ? 'Keine'
                : (
                  laneSigns
                    .filter((sign) => sign.iconUrl)
                    .map(({ signCode, iconUrl }) => (
                      <div key={signCode}>
                        <img
                          src={iconUrl}
                          alt={signCode}
                          style={{
                            width: '40px',
                            height: '40px',
                          }}
                        />
                        <div
                          style={{
                            width: '40px',
                            textAlign: 'center',
                          }}
                        >
                          { signCode }
                        </div>
                      </div>
                    ))
                )
            }
          </dd>

          <dt>Begleitet Straße</dt>
          <dd>
            { [name, ref].filter(Boolean).join(' / ') }
          </dd>

          <dt>Zulässige Höchstgeschwindigkeit der Straße</dt>
          <dd>
            { maxspeedHuman }
          </dd>

          <dt>Quelle für zulässige Höchstgeschwindigkeit</dt>
          <dd>
            { maxspeedSourceHuman }
          </dd>
        </dl>
      </Popup>
    </Polyline>
  )
}

function Cycleway({ cycleway, zoom }) {
  const {
    tags: {
      highway,
      bicycle_road: bicycleRoad,
    }
  } = cycleway

  if (['path', 'cycleway'].includes(highway)) {
    return <Track cycleway={cycleway} zoom={zoom} />
  }

  if (bicycleRoad === 'yes') {
    return <Track cycleway={cycleway} zoom={zoom} />
  }

  return <Lane cycleway={cycleway} zoom={zoom} />
}

function MapContent(props) {
  const {
    filteredWays,
    filter,
  } = props

  const [zoom, setZoom] = useState(DEFAULT_ZOOM_LEVEL);

  const map = useMapEvents({
    zoomend: () => {
      setZoom(map.getZoom())
    },
  })

  return (
    <>
      {
        filteredWays
          .map((cycleway) => <Cycleway key={`cycleway-${cycleway.id}-${cycleway.side || ''}`} cycleway={cycleway} zoom={zoom} />)
      }
      {
        signs
          .filter((sign) => applySignFilter(filter, filteredWays, sign))
          .map((sign) => {
            const {
              cycleway,
            } = sign

            return (
              <Sign key={`sign-${sign.id}`} sign={sign} cycleway={cycleway} zoom={zoom} />
            )
          })
      }
    </>
  )
}

L.Control.Legend = L.Control.extend({
  onAdd: function(map) {
    const div = L.DomUtil.create('div')
    const root = createRoot(div)

    root.render((
      <div
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: '5px',
          padding: '0 5px',
          margin: 0,
          color: '#333',
        }}
      >
        <h5>Legende</h5>
        <div style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
          <div style={{ border: '2px solid purple', height: '2px', width: '50px' }} />
          <div>Fahrradstraße</div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
          <div style={{ border: '2px solid green', height: '2px', width: '50px' }} />
          <div>Keine Benutzungspflicht</div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
          <div style={{ border: '2px solid blue', height: '2px', width: '50px' }} />
          <div>Benutzungspflichtig in Fahrbahnrichtung</div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
          <div style={{ border: '2px solid red', height: '2px', width: '50px' }} />
          <div>Benutzungspflichtig in beide Richtungen</div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
          <div style={{ border: '2px solid black', height: '2px', width: '50px' }} />
          <div>Benutzungspflichtig in Gegenrichtung</div>
        </div>
      </div>
    ));

    return div
  },

  onRemove: function(map) {
    // Nothing to do here
  }
})

function Legend(props) {
  const map = useMap()

  useEffect(() => {
    const legend = (new L.Control.Legend({ position: 'bottomright' })).addTo(map)

    return () => {
      legend.remove()

      /* to clear the legend state, remove the container reference */
      legend._container = null
    }
  }, [map])

  return <></>
}

function distanceHuman(distance) {
  if (distance > 1000) {
    return `${Math.round(distance / 100) / 10}km`
  }

  return `${Math.round(distance)}m`
}

function Statistics(props) {
  const {
    distance,
  } = props

  const map = useMap()

  useEffect(() => {
    L.Control.Statistics = L.Control.extend({
      onAdd: function(map) {
        const div = L.DomUtil.create('div')
        const root = createRoot(div)

        root.render((
          <div
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              borderRadius: '5px',
              padding: '0 5px',
              margin: 0,
              color: '#333',
            }}
          >
            <p>
              <strong>Länge der gefilterten Wege:</strong> { distanceHuman(distance) }
            </p>
          </div>
        ));

        return div
      },

      onRemove: function(map) {
        // Nothing to do here
      }
    })

    const statistics = (new L.Control.Statistics({ position: 'bottomright' })).addTo(map)

    return () => {
      statistics.remove()

      /* to clear the legend state, remove the container reference */
      statistics._container = null
    }
  }, [
    distance,
    map,
  ])

  return <></>
}

function Sidebar(props) {
  const map = useMap()

  useEffect(() => {
    const sidebar = L.control.sidebar('sidebar').addTo(map)

    return () => {
      sidebar.remove();

      /* to clear the sidebar state, remove the container reference */
      sidebar._container = null
    }
  }, [map])

  return <></>
}

function SidebarContent() {
  return (
    <div
      id="sidebar"
      className="sidebar collapsed"
    >
      <div className="sidebar-tabs">
        <ul role="tablist">
          <li><a href="#filter" role="tab"><MdFilterAlt /></a></li>
          <li><a href="#info" role="tab"><MdInfo /></a></li>
        </ul>
      </div>
      <div className="sidebar-content">
        <div className="sidebar-pane" id="filter">
          <h1 className="sidebar-header">
            Filter
            <div className="sidebar-close"><MdClose /></div>
          </h1>
          <FilterBar />
        </div>
        <div className="sidebar-pane" id="info">
          <h1 className="sidebar-header">
            Info
            <div className="sidebar-close"><MdClose /></div>
          </h1>
          <p>
            {
              REACT_APP_PROVIDED_BY_NAME && (
                <>
                  Ein Projekt von&nbsp;
                  <a
                    href={REACT_APP_PROVIDED_BY_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    { REACT_APP_PROVIDED_BY_NAME }
                  </a>.
                  <br />
                </>
              )
            }
            {
              REACT_APP_PROVIDED_BY_EMAIL && (
                <>
                  <a
                    href={`mailto:${REACT_APP_PROVIDED_BY_EMAIL}`}
                  >
                    { REACT_APP_PROVIDED_BY_EMAIL }
                  </a>
                  <br />
                </>
              )
            }
            {
              REACT_APP_PROVIDED_BY_MASTODON && (
                <a
                  href={REACT_APP_PROVIDED_BY_MASTODON}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginRight: '1em' }}
                >
                  <img src="/Mastodon_Logotype_(Simple).svg" width="24px" alt="Mastodon Logo" />
                </a>
              )
            }
            {
              REACT_APP_PROVIDED_BY_TWITTER && (
                <a
                  href={REACT_APP_PROVIDED_BY_TWITTER}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/Twitter-logo.svg" width="24px" alt="Twitter Logo" />
                </a>
              )
            }
          </p>

          <p>
            Quellcode: <a
              href="https://gitlab.com/metabolite/cycleway-map"
              target="_blank"
              rel="noreferrer"
            >
              GitLab
            </a>
            <br />
            Lizenz: <a
              href="https://www.gnu.org/licenses/agpl-3.0.en.html"
              target="_blank"
              rel="noreferrer"
            >
              GNU Affero General Public License
            </a>
          </p>

          <p>Die zugrunde liegenden Daten stehen der Allgemeinheit als Open Data über das&nbsp;
            <a
              href="https://www.openstreetmap.org"
              target="_blank"
              rel="noreferrer"
            >
              OpenStreetMap-Projekt
            </a> unter der <a
              href="https://opendatacommons.org/licenses/odbl/1-0/"
              target="_blank"
              rel="noreferrer"
            >Open Data Commons Open Database License (ODbL)</a> zur Verfügung.
          </p>
          <p>
            Letzte Aktualisierung der Daten:<br />
            { DateTime.fromISO(meta.lastUpdatedAt).toLocaleString(DateTime.DATETIME_FULL) }
          </p>
          <p>
            <a
              href={REACT_APP_IMPRINT_URL}
              target="_blank"
              rel="noreferrer"
            >
              Impressum
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

function MapPage(props) {
  const [searchParams] = useSearchParams()

  const filter = searchParamsToFilter(searchParams)

  const filteredWays = cycleways.filter((cycleway) => applyWayFilter(filter, cycleway))

  const distance = filteredWays.reduce((acc, cycleway) => {
    const wayLength = cycleway.positions.reduce(
      (wayAcc, position, index) => {
        if (index + 1 === cycleway.positions.length) {
          return wayAcc
        }
        return wayAcc + Math.abs(haversine(
          position[0],
          position[1],
          cycleway.positions[index + 1][0],
          cycleway.positions[index + 1][1],
        ))
      },
      0,
    )

    return acc + wayLength
  }, 0)

  return (
    <>
      <SidebarContent />
      <MapContainer center={MAP_CENTER} zoom={13} style={{ height: '100vh' }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxNativeZoom={19}
          maxZoom={25}
        />
        <Sidebar />
        <MapContent filter={filter} filteredWays={filteredWays} />
        <Legend />
        <Statistics distance={distance} />
      </MapContainer>
    </>
  )
}

export default MapPage
