import React from 'react'
import Select from 'react-select'
import { Form } from 'react-bootstrap'
import {
  useSearchParams,
} from 'react-router-dom'
import {
  filters,
  wayFilterCategories,
  signFilterCategories,
} from '../../filters'

function reactSelectToFilter(selected) {
  return selected.map((option) => option.value)
}

function filterToReactSelect(options, filter) {
  let selected = []
  if (Array.isArray(filter)) {
    selected = filter
  } else if (filter) {
    selected = [filter.toString()]
  }

  return options.filter((option) => selected.includes(option.value.toString()))
}

export function searchParamsToFilter(entries) {
  const result = {}
  for(const [key, value] of entries) {
    const filterConfig = filters[key]
    if (!filterConfig) {
      continue
    }
    if (filterConfig.type === 'multi-select') {
      if (!(key in result)) {
        result[key] = [value]
      } else {
        result[key].push(value)
      }
    } else {
      result[key] = value
    }
  }
  return result
}

function renderFilterControl(filterId, value, setFilter) {
  const {
    type,
    label,
    disabled,
    options,
    data,
  } = filters[filterId]

  if (type === 'single-select') {
    return (
      <>
        <Form.Label>{ label }</Form.Label>
        <Form.Control
          as="select"
          disabled={disabled}
          onChange={(event) => setFilter(filterId, event.target.value)}
          value={value}
        >
          {
            options.map((option) => (
              <option
                key={`filter-option-${data}-${filterId}-${option.value || 'null'}`}
                value={option.value}
              >
                { option.label }
              </option>
            ))
          }
        </Form.Control>
      </>
    )
  }

  if (type === 'multi-select') {
    return (
      <>
        <Form.Label>{ label }</Form.Label>
        <Select
          isMulti
          options={options}
          value={filterToReactSelect(options, value)}
          placeholder="Alle"
          onChange={(value) => setFilter(filterId, reactSelectToFilter(value))}
        />
      </>
    )
  }

  if (type === 'boolean') {
    return (
      <Form.Check
        label={label}
        checked={value === 'true'}
        onChange={(event) => setFilter(filterId, event.target.checked)}
      />
    )
  }
}

function renderFilter(filterId, value, setFilter) {
  return (
    <Form.Group
      key={filterId}
      style={{ marginBottom: '0.5rem' }}
    >
      {
        renderFilterControl(filterId, value, setFilter)
      }
    </Form.Group>
  )
}


function FilterBar(props) {
  const [searchParams, setSearchParams] = useSearchParams()

  const filter = searchParamsToFilter(searchParams)

  function setFilter(param, value) {
    if ([undefined, null, '', false].includes(value)) {
      delete filter[param]
    } else {
      filter[param] = value
    }

    setSearchParams(filter)
  }


  return (
    <Form>
      <section>
        <h4>Wege</h4>
        {
          Object.keys(wayFilterCategories).map((categoryId) => (
            <section key={categoryId} style={{ marginTop: '1em' }}>
              <h5>{ wayFilterCategories[categoryId].label }</h5>
              {
                Object.keys(filters)
                  .filter((filterId) => filters[filterId].data === 'ways')
                  .filter((filterId) => filters[filterId].category === categoryId)
                  .map((filterId) => renderFilter(filterId, filter[filterId], setFilter))
              }
            </section>
          ))
        }
      </section>
      <section style={{ marginTop: '2em' }}>
        <h4>Schilder</h4>
        {
          Object.keys(signFilterCategories).map((categoryId) => (
            <section key={categoryId} style={{ marginTop: '1em' }}>
              <h5>{ signFilterCategories[categoryId].label }</h5>
              {
                Object.keys(filters)
                  .filter((filterId) => filters[filterId].data === 'signs')
                  .filter((filterId) => filters[filterId].category === categoryId)
                  .map((filterId) => renderFilter(filterId, filter[filterId], setFilter))
              }
            </section>
          ))
        }
      </section>
    </Form>
  )
}

export default FilterBar
